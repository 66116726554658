import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { API_URL, getCart, getOrder, setOrder } from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import Input from "../components/form/Input"
import Checkbox from "../components/form/Checkbox"
import Textarea from "../components/form/Textarea"
import Msg from "../components/form/Msg"
import Submit from "../components/form/Submit"
import {
  Main,
  MainContainer,
  Grid,
  ButtonContainer,
} from "../components/content/Address/styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Address = () => {
  const [errors, setErrors] = useState([])
  const [names, setNames] = useState({
    same: false,
  })
  const [msg, setMsg] = useState({})

  useEffect(() => {
    if (getOrder() && getOrder().contact) {
      let contact = {
        fname: "",
        lname: "",
        email: "",
        phone: "",
      }
      let shipping = {
        address: "",
        address2: "",
        city: "",
        st: "",
        zip: "",
        notes: "",
      }
      const obj = getOrder()
      if (obj.contact) {
        contact.fname = obj.contact.fname
        contact.lname = obj.contact.lname
        contact.email = obj.contact.email
        contact.phone = obj.contact.phone
      }
      if (obj.shipping) {
        shipping.address = obj.shipping.address
        shipping.address2 = obj.shipping.address2
        shipping.city = obj.shipping.city
        shipping.st = obj.shipping.st
        shipping.zip = obj.shipping.zip
        shipping.notes = obj.shipping.notes
      } else if (obj.zip) {
        shipping.city = obj.city
        shipping.st = obj.st
        shipping.zip = obj.zip
      }
      init(contact, shipping)
    }
  }, [])

  const init = (contact, shipping) => {
    setNames({
      ...names,
      ...contact,
      ...shipping,
    })
  }

  const handleUpdate = (name, value) => {
    if (name === "same") {
      setNames(names => ({ ...names, same: !names.same }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/checkoutAddress`)
    let data = new FormData()
    data.append("fname", names.fname || "")
    data.append("lname", names.lname || "")
    data.append("email", names.email || "")
    data.append("phone", names.phone || "")
    data.append("address", names.address || "")
    data.append("address2", names.address2 || "")
    data.append("city", names.city || "")
    data.append("st", names.st || "")
    data.append("zip", names.zip || "")
    data.append("same", names.same ? "1" : "0")
    data.append("notes", names.notes || "")
    data.append("cart", JSON.stringify(getCart()) || "[]")
    data.append("orderZip", getOrder().zip || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        let order = {}
        let contact = {
          fname: json.fname,
          lname: json.lname,
          email: json.email,
          phone: json.phone,
        }
        let shipping = {
          address: json.address,
          address2: json.address2,
          city: json.city,
          st: json.st,
          zip: json.zip,
          notes: json.notes,
        }
        let billing = {
          address: json.billAddress,
          address2: json.billAddress2,
          city: json.billCity,
          st: json.billSt,
          zip: json.billZip,
        }
        if (getOrder() && getOrder().contact) {
          order = getOrder()
        }
        order.contact = contact
        order.shipping = shipping
        if (order.billing && !billing.address) {
        } else {
          order.billing = billing
        }
        setOrder(order)
        navigate(json.next)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Layout>
      <Helmet
        name="Installation / Shipping Address"
        description="Enter the installation and shipping address for your order."
        slug="address"
      />
      <Header />
      <PageHdr title="Installation / Shipping Address" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <h2>Enter the shipping details for this order.</h2>
              <form method="post" onSubmit={e => handleSubmit(e)}>
                <Grid>
                  <p>Please enter your name & contact information.</p>
                  <p>Please enter your installation/shipping address.</p>
                  <div className="fields">
                    <div className="contact">
                      <div>
                        <Input
                          type="text"
                          label="First Name"
                          req={true}
                          name="fname"
                          value={names.fname}
                          update={handleUpdate}
                          errors={errors}
                          autocomplete="given-name"
                        />
                        <Input
                          type="text"
                          label="Last Name"
                          req={true}
                          name="lname"
                          value={names.lname}
                          update={handleUpdate}
                          errors={errors}
                          autocomplete="family-name"
                        />
                      </div>
                      <div className="top-margin">
                        <Input
                          type="text"
                          label="Email Address"
                          req={true}
                          name="email"
                          value={names.email}
                          update={handleUpdate}
                          errors={errors}
                        />
                        <Input
                          type="text"
                          label="Phone"
                          req={true}
                          name="phone"
                          value={names.phone}
                          update={handleUpdate}
                          errors={errors}
                          autocomplete="tel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fields second">
                    <div className="install">
                      <div>
                        <Input
                          type="text"
                          label="Address"
                          req={true}
                          name="address"
                          value={names.address}
                          update={handleUpdate}
                          errors={errors}
                          autocomplete="street-address"
                        />
                        <Input
                          type="text"
                          label="Address 2"
                          req={false}
                          name="address2"
                          value={names.address2}
                          update={handleUpdate}
                          errors={errors}
                        />
                      </div>
                      <div className="three top-margin">
                        <Input
                          type="text"
                          label="City"
                          req={true}
                          name="city"
                          value={names.city}
                          update={handleUpdate}
                          errors={errors}
                          autocomplete="address-level2"
                        />
                        <Input
                          type="text"
                          label="State"
                          req={true}
                          name="st"
                          value={names.st}
                          update={handleUpdate}
                          errors={errors}
                          autocomplete="address-level1"
                        />
                        <Input
                          type="text"
                          label="Zip"
                          req={true}
                          name="zip"
                          value={names.zip}
                          update={handleUpdate}
                          errors={errors}
                          autocomplete="postal-code"
                        />
                      </div>

                      <div className="one top-margin">
                        <Checkbox
                          name="same"
                          update={handleUpdate}
                          data={[
                            {
                              value: false,
                              label: "My billing address is the same as above",
                            },
                          ].map(item => {
                            return {
                              value: item.value,
                              label: item.label,
                              checked: names.same,
                            }
                          })}
                        />
                      </div>

                      <div className="one top-margin bottom-margin">
                        <Textarea
                          label="Install / Order Notes"
                          req={false}
                          name="notes"
                          value={names.notes}
                          update={handleUpdate}
                          errors={errors}
                        />
                      </div>

                      <div className="one">
                        <p>
                          Indicate above any specific information that we may
                          need to know ahead of delivery and installation.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="submit">
                    {msg.type && <Msg data={msg} />}

                    <ButtonContainer
                      className={msg.type === "working" ? "hidden" : ""}
                    >
                      <Submit name="Continue" icon={faChevronCircleRight} />
                    </ButtonContainer>
                  </div>
                </Grid>
              </form>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Address
