import styled from "styled-components"

import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
  Grid as BaseGrid,
  ButtonContainer as BaseButtonContainer,
} from "../../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)``

export const Grid = styled(BaseGrid)`
  grid-column-gap: 15px;

  & > p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12.5px 25px 0 25px;
    order: 0;
  }

  & > div.fields {
    order: 1;
  }

  & > p:nth-child(2) {
    @media screen and (max-width: 767px) {
      order: 2;
      margin-top: 0;
      padding-top: 0;
    }
  }

  & > div.fields.second {
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }

  & div.contact,
  & div.install {
    border: 1px solid #ddd;
    padding: 15px 15px 25px 15px;

    & > div {
      display: flex;
      align-items: center;

      & > div:first-child {
        margin-right: 7.5px;
      }
      & > div:last-child {
        margin-left: 7.5px;
      }

      @media screen and (max-width: 1023px) {
        flex-direction: column;

        & > div:first-child {
          margin-right: 0;
        }
        & > div:last-child {
          margin-left: 0;
        }
        & > div {
          margin-bottom: 15px;
        }
      }
    }

    & > div.three {
      & > div:first-child {
        margin-right: 0;
      }
      & > div:last-child {
        margin-left: 0;
      }
      & > div:nth-child(2) {
        margin-right: 15px;
        margin-left: 15px;
      }

      @media screen and (max-width: 1023px) {
        & > div:nth-child(2) {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    & > div.one {
      & > div:first-child {
        margin-right: 0;
      }
      & > div:last-child {
        margin-left: 0;
      }
    }

    & > div.top-margin {
      margin-top: 15px;

      @media screen and (max-width: 1023px) {
        margin-top: 0;
      }
    }

    & > div.bottom-margin {
      margin-bottom: 10px;

      @media screen and (max-width: 1023px) {
        margin-bottom: 0;
      }
    }
  }

  & div.install {
    padding-bottom: 15px;

    & p {
      font-size: 0.85rem;
      line-height: 18px;
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  & > p:nth-child(2) {
    @media screen and (max-width: 767px) {
      margin-top: 25px;
    }
  }

  & > div.table.inside {
    @media screen and (max-width: 767px) {
    }
  }

  & > div.submit {
    border: 1px solid #ddd;
    margin-top: 30px;
    grid-column: 1 / span 2;

    order: 2;

    @media screen and (max-width: 767px) {
      grid-column: 1 / span 1;
      order: 4;
    }
  }
`

export const ButtonContainer = styled(BaseButtonContainer)`
  padding: 30px 25%;

  @media screen and (max-width: 767px) {
    padding: 30px;
  }
`
